import React, { useState } from 'react';
import './login.css'
import { Link, useNavigate } from 'react-router-dom';
// import logo from '../../Assets/Images/modelverse-logo.png'


const Login = (props) => {

    const BASE_URL = props.BASE_URL

    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const userData = {
        email: email,
        password: password,
    };

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(userData),
    };

    const handleLogin = () => {
        // Implement login logic here

        if (password === '' || email === '') {
            alert('Please Enter Required details in All Fields')
            return;
        }

        fetch(`${BASE_URL}/auth/sign-in`, requestOptions)
            .then((response) => {
                // if (!response.ok) {
                //     throw new Error(`HTTP error! Status: ${response.status}`);
                // }
                return response.json(); // Parse the response JSON
            })
            .then((json) => {
                // Handle the API response data here
                if (json.success === true) {
                    console.log('API Response:', json);
                    localStorage.setItem('userToken', json.token);
                    // console.log(json.token);
                    localStorage.setItem('userName', json.data.user.username);
                    // console.log(json.data.user.username);
                    // navigate('/in')
                    window.location.href = "/in";
                }
                else {
                    alert(json.message);
                    console.log('API Response:', json);
                }
            })
            .catch((error) => {
                console.error('API Error:', error);
            });

        console.log(`Logging in with email: ${email} and password: ${password}`);
    };

    return (
        <div className='login-container'>
            <div className='login-card'>
                {/* <img src={logo} className='logo-image' alt="" /> */}
                <h2>Login</h2>
                <input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <button className='login-btn' onClick={handleLogin}>Login</button>
                <p className='register-link'>
                    Don't have an account? <Link to='/signup'>Register Now</Link>
                </p>
            </div>
        </div>
    );
};

export default Login;
