// import React, { useEffect, useState } from 'react'
// import './home.css'

// const Home = () => {


//     const BASE_URL = "http://127.0.0.1:8888";
//     const image = new File(["./examples/imgs/bear.jpg"], "bear.jpg");
//     const source = new File(["./examples/imgs/s.jpg"], "s.jpg");
//     const mask = new File(["./examples/imgs/m.png"], "m.png");

//     const [prompt, setPrompt] = useState('')

//     // const handleGenerate = () => {

//     //     const formData = new FormData();

//     //     formData.append("cn_img1", "string_content_for_cn_img1");
//     //     formData.append("input_image", "string_content_for_input_image");
//     //     formData.append("input_mask", "string_content_for_input_mask");
//     //     formData.append("inpaint_additional_prompt", "inpaint_additional_prompt_value");
//     //     formData.append("outpaint_selections", ["Left", "Right", "Top", "Bottom"]);
//     //     formData.append("outpaint_distance_left", 10);
//     //     formData.append("outpaint_distance_right", 10);
//     //     formData.append("outpaint_distance_top", 5);
//     //     formData.append("outpaint_distance_bottom", 5);
//     //     formData.append("cn_stop1", "cn_stop1_value");
//     //     formData.append("cn_weight1", "cn_weight1_value");
//     //     formData.append("cn_type1", "cn_type1_value");
//     //     formData.append("cn_img2", "string_content_for_cn_img2");
//     //     formData.append("cn_stop2", "cn_stop2_value");
//     //     formData.append("cn_weight2", "cn_weight2_value");
//     //     formData.append("cn_type2", "cn_type2_value");
//     //     formData.append("cn_img3", "string_content_for_cn_img3");
//     //     formData.append("cn_stop3", "cn_stop3_value");
//     //     formData.append("cn_weight3", "cn_weight3_value");
//     //     formData.append("cn_type3", "cn_type3_value");
//     //     formData.append("cn_img4", "string_content_for_cn_img4");
//     //     formData.append("cn_stop4", "cn_stop4_value");
//     //     formData.append("cn_weight4", "cn_weight4_value");
//     //     formData.append("cn_type4", "cn_type4_value");
//     //     formData.append("prompt", prompt);
//     //     formData.append("negative_prompt", "negative_prompt_value");
//     //     formData.append("style_selections", ["style1", "style2"]);
//     //     formData.append("performance_selection", "performance_selection_value");
//     //     formData.append("aspect_ratios_selection", "aspect_ratios_selection_value");
//     //     formData.append("image_number", 10);
//     //     formData.append("image_seed", -1);
//     //     formData.append("sharpness", 15);
//     //     formData.append("guidance_scale", 10);
//     //     formData.append("base_model_name", "base_model_name_value");
//     //     formData.append("refiner_model_name", "refiner_model_name_value");
//     //     formData.append("refiner_switch", 0.5);
//     //     formData.append("loras", JSON.stringify([{"model_name": "sd_xl_offset_example-lora_1.0.safetensors", "weight": 0.5}]));
//     //     formData.append("advanced_params", JSON.stringify({"param1": "value1", "param2": "value2"}));
//     //     formData.append("require_base64", true);
//     //     formData.append("async_process", true);


//     //     // const body = {
//     //     //     cn_img1: "string_content_for_cn_img1",
//     //     //     input_image: "string_content_for_input_image",
//     //     //     input_mask: "string_content_for_input_mask",
//     //     //     inpaint_additional_prompt: "inpaint_additional_prompt_value",
//     //     //     outpaint_selections: ["Left", "Right", "Top", "Bottom"],
//     //     //     outpaint_distance_left: 10,
//     //     //     outpaint_distance_right: 10,
//     //     //     outpaint_distance_top: 5,
//     //     //     outpaint_distance_bottom: 5,
//     //     //     cn_stop1: "cn_stop1_value",
//     //     //     cn_weight1: "cn_weight1_value",
//     //     //     cn_type1: "cn_type1_value",
//     //     //     cn_img2: "string_content_for_cn_img2",
//     //     //     cn_stop2: "cn_stop2_value",
//     //     //     cn_weight2: "cn_weight2_value",
//     //     //     cn_type2: "cn_type2_value",
//     //     //     cn_img3: "string_content_for_cn_img3",
//     //     //     cn_stop3: "cn_stop3_value",
//     //     //     cn_weight3: "cn_weight3_value",
//     //     //     cn_type3: "cn_type3_value",
//     //     //     cn_img4: "string_content_for_cn_img4",
//     //     //     cn_stop4: "cn_stop4_value",
//     //     //     cn_weight4: "cn_weight4_value",
//     //     //     cn_type4: "cn_type4_value",
//     //     //     prompt: prompt,
//     //     //     negative_prompt: "negative_prompt_value",
//     //     //     style_selections: ["style1", "style2"],
//     //     //     performance_selection: "performance_selection_value",
//     //     //     aspect_ratios_selection: "aspect_ratios_selection_value",
//     //     //     image_number: 10,
//     //     //     image_seed: -1,
//     //     //     sharpness: 15,
//     //     //     guidance_scale: 10,
//     //     //     base_model_name: "base_model_name_value",
//     //     //     refiner_model_name: "refiner_model_name_value",
//     //     //     refiner_switch: 0.5,
//     //     //     loras: [{ "model_name": "sd_xl_offset_example-lora_1.0.safetensors", "weight": 0.5 }],
//     //     //     advanced_params: { "param1": "value1", "param2": "value2" },
//     //     //     require_base64: true,
//     //     //     async_process: true
//     //     // }

//     //     const requestOptions = {
//     //         method: 'POST',
//     //         headers: {
//     //             'Content-Type': 'application/json',
//     //         },
//     //         body: formData,
//     //     };

//     //     fetch(`${BASE_URL}/v1/generation/image-prompt`, requestOptions)
//     //         .then((response) => response.json())
//     //         .then((json) => console.log(json))
//     //         .catch((error) => console.error("Error", error));

//     // }

//     useEffect(() => {

//         const extendParams = {
//             outpaint_selections: ["Left", "Right"],
//             image_prompts: [], // required, can be an empty list
//         };
//         handleGenerate(extendParams, image);

//         // Example usage: Inpaint
//         const inpaintParams = {
//             prompt: "1girl sitting on the chair",
//             image_prompts: [], // required, can be an empty list
//             async_process: true,
//         };
//         handleGenerate(inpaintParams, source, mask);

//         // Example usage: Image Prompt
//         const promptParams = {
//             prompt: "1girl sitting on the chair",
//             image_prompts: [
//                 {
//                     cn_stop: 0.6,
//                     cn_weight: 0.6,
//                     cn_type: "ImagePrompt",
//                 },
//                 {
//                     cn_stop: 0.6,
//                     cn_weight: 0.6,
//                     cn_type: "ImagePrompt",
//                 },
//             ],
//         };
//         handleGenerate(promptParams, image, source);

//     }, [image])


//     const handleGenerate = async (params, inputImage, inputMask, cnImg1, cnImg2, cnImg3, cnImg4) => {
//         const formData = new FormData();
//         formData.append('input_image', inputImage);
//         formData.append('input_mask', inputMask);
//         formData.append('cn_img1', cnImg1);
//         formData.append('cn_img2', cnImg2);
//         formData.append('cn_img3', cnImg3);
//         formData.append('cn_img4', cnImg4);

//         try {
//             const response = await fetch(`${BASE_URL}/v1/generation/image-prompt`, {
//                 method: 'POST',
//                 body: formData,
//             });

//             const result = await response.json();
//             console.log(result);
//         } catch (error) {
//             console.error('Error:', error);
//         }
//     };

//     return (
//         <>
//             <div className='main-container'>
//                 <div className='image-container'>
//                     <img src="" alt="" />
//                 </div>
//                 <div className='input-container'>
//                     <label>Prompt</label>
//                     <input type="text" value={prompt} onChange={(e) => setPrompt(e.target.value)} />
//                 </div>

//                 <div className='btn-container'>
//                     <button onClick={handleGenerate}>Generate</button>
//                 </div>
//             </div>
//         </>
//     )
// }

// export default Home


import React, { useEffect, useState } from 'react';
import './home.css'
import DrawingCanvas from '../../Components/DrawingCanvas';
import Loader from '../../Components/Loader';
import TopNav from '../../Components/TopNav';


const Home = ({ BASE_URL }) => {

    // const BASE_URL = 'http://3.109.121.1/';

    const userToken = localStorage.getItem('userToken');

    const [prompt, setPrompt] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [imageWidth, setImageWidth] = useState(0)
    const [imageHeight, setImageHeight] = useState(0)

    const [enableImage1, setEnableImage1] = useState(false);
    const [enableImage2, setEnableImage2] = useState(false);
    const [enableImage3, setEnableImage3] = useState(false);


    const [image, setImage] = useState(null)
    const [previewImage, setPreviewImage] = useState(null)

    const [isLoading, setIsLoading] = useState(false);

    const [maskedImage, setMaskedImage] = useState(null);

    const [imageFiles, setImageFiles] = useState({
        cn_img1: null,
        cn_img2: null,
        cn_img3: null,
    });

    const handleFileChange = (event, fieldName) => {
        const file = event.target.files[0];
        setImageFiles(prevFiles => ({
            ...prevFiles,
            [fieldName]: file,
        }));
    };


    const receiveImageFromChild = (image) => {
        setMaskedImage(image);
        console.log("Masked Image Result: ", image)
    };


    useEffect(() => {
        const img = new Image();
        img.src = image;

        img.onload = async () => {
            setImageWidth(img.width);
            setImageHeight(img.height);
        };

        return () => {
            img.src = null;
        };

    }, [image]);

    const handleCheckboxChange = (checkboxName) => {
        switch (checkboxName) {
            case 'cn_img1':
                setEnableImage1(!enableImage1);
                if (!enableImage1) {
                    setImageFiles(prevFiles => ({ ...prevFiles, cn_img1: null }));
                }
                break;
            case 'cn_img2':
                setEnableImage2(!enableImage2);
                if (!enableImage2) {
                    setImageFiles(prevFiles => ({ ...prevFiles, cn_img2: null }));
                }
                break;
            case 'cn_img3':
                setEnableImage3(!enableImage3);
                if (!enableImage3) {
                    setImageFiles(prevFiles => ({ ...prevFiles, cn_img3: null }));
                }
                break;
            default:
                break;
        }
    };


    function base64ToFile(base64, filename) {
        // Remove the data URI portion and keep only the base64 data
        const base64Data = base64.split(',')[1];

        // Convert base64 data to a binary array
        const binaryData = atob(base64Data);

        // Create a Uint8Array from the binary data
        const uint8Array = new Uint8Array(binaryData.length);
        for (let i = 0; i < binaryData.length; i++) {
            uint8Array[i] = binaryData.charCodeAt(i);
        }

        // Create a Blob with the MIME type (e.g., "image/png")
        const blob = new Blob([uint8Array], { type: 'image/png' });

        // Create a File from the Blob with the provided filename
        const file = new File([blob], filename, { type: blob.type });

        return file;
    }

    const handleUploadImage = async () => {
        const item = {
            id: `${selectedFile.name}-${Date.now()}`,
            name: selectedFile.name,
            type: selectedFile.type.slice(6),
            file: selectedFile,
            status: '',
            size:
                selectedFile.size > 1024
                    ? selectedFile.size > 1048576
                        ? Math.round(selectedFile.size / 1048576) + 'mb'
                        : Math.round(selectedFile.size / 1024) + 'kb'
                    : selectedFile.size + 'b',
        }

        if (selectedFile === null) {
            alert('Please select a file to continue');
            return;
        }

        setIsLoading(true);

        try {
            const formData = new FormData()
            // form.append('image_file', item.file)
            // form.append('type', item.type)
            // form.append('mask_file', maskedImage)

            formData.append('input_image', item.file);
            // formData.append('input_mask', maskedImage);
            if (imageFiles.cn_img1) {
                formData.append('cn_img1', imageFiles['cn_img1']);
            }
            if (imageFiles.cn_img2) {
                formData.append('cn_img2', imageFiles['cn_img2']);
            }
            if (imageFiles.cn_img3) {
                formData.append('cn_img3', imageFiles['cn_img3']);
            }
            // formData.append('cn_img4', cnImg4);

            try {
                const response = await fetch(`${BASE_URL}/users/remove-object`, {
                    // const response = await fetch(`${BASE_URL}v1/generation/image-prompt`, {

                    method: 'POST',
                    headers: {
                        // 'Access-Control-Allow-Origin': `http://localhost:3001/in`,
                        // 'Access-Control-Allow-Methods': 'POST',
                        Authorization: `Bearer ${userToken}`
                    },
                    body: formData,
                });

                const result = await response.json();

                if (result && result.success) {
                    setPreviewImage(result.data.url);
                    setImage(result.data.url);
                    setIsLoading(false);
                    console.log(result);
                }
            } catch (error) {
                console.error('Error:', error);
                setIsLoading(false);
            }

            // const response = await fetch(`${BASE_URL}/users/remove-object`, {
            //     method: 'POST',
            //     headers: {
            //         Authorization: `Bearer ${userToken}`
            //     },
            //     body: form,
            // })

            // if (response.status && response.status > 400) {
            //     const json = await response.json()
            //     if (!json.status) {
            //         alert(json.message);
            //         setIsLoading(false);
            //         return
            //     }
            //     // throw new Error(response.status + ' ' + text)
            // }

            // response.json().then(async (json) => {
            //     const base64Image = await json.base64Image;
            //     setPreviewImage(`data:image/png;base64,${base64Image}`);
            //     const imageResult = base64ToFile(previewImage, 'masked-image.png')
            //     setSelectedFile(imageResult);

            //     setIsLoading(false);
            // })


        } catch (e) {
            console.log(e)
            setIsLoading(false);
        }
    }

    const handleImage = (e) => {
        const file = e.target.files[0];
        console.log(file)
        setSelectedFile(file);

        if (file) {
            const reader = new FileReader();

            reader.onloadend = () => {
                // The result contains the base64 image data
                const base64ImageData = reader.result;
                setImage(base64ImageData);
                setPreviewImage(base64ImageData)
            };

            // Read the file as a data URL (base64)
            reader.readAsDataURL(file);
        }

        // setImage(URL.createObjectURL(file));
        // setPostStatus("MEDIA")
    };

    const handleDownload = () => {
        // Check if there's a preview image to download
        if (previewImage) {
            const downloadLink = document.createElement('a');
            console.log("Preview Image --- >> ", previewImage)
            downloadLink.href = previewImage;
            downloadLink.download = 'object-removed.png';
            downloadLink.click();
        }
    };

    return (
        <>
            <TopNav />
            <div className='image-upload-container'>

                <div className="top">
                    <h1 className="title">GENERATE</h1>
                    {/* <p className='desc'>Erase any Object online in 1 single click!</p> */}
                </div>

                <div className="image-upload">
                    <div className='input-container'>
                        <input
                            type="file"
                            id="fileInput"
                            accept="image/*"
                            onChange={handleImage}
                        />
                        <div className='image-input-text'>
                            <p>Drag and drop an image here</p>
                            <p>Or</p>
                            <p>Choose Image from your Files..</p>
                        </div>
                    </div>
                    {previewImage ? (
                        <div className="upload-text">
                            <p>Preview Image</p>
                            {/* <label htmlFor="fileInput"> */}
                            {/* Browse for an image */}
                            {/* </label> */}
                            <div className='preview-image'>
                                {previewImage ? (
                                    // <>
                                    //     {/* <img src={previewImage} alt="Selected" style={{ maxWidth: '100%', maxHeight: '300px' }} /> */}
                                    //     <DrawingCanvas imageSrc={previewImage}
                                    //         imageWidth={imageWidth}
                                    //         imageHeight={imageHeight}
                                    //         sendImageToParent={receiveImageFromChild}
                                    //         isLoading={isLoading}
                                    //     ></DrawingCanvas>
                                    //     <button className="btn delete-button" onClick={() => { setImage(null); setPreviewImage(null); }}>
                                    //         <span>&times;</span>
                                    //     </button>
                                    // </>
                                    <div className='preview-image'>
                                        <>
                                            <img src={previewImage} alt="Selected" style={{ maxWidth: '100%', maxHeight: '340px' }} />
                                            <button className="btn delete-button" onClick={() => { setImage(null); setPreviewImage(null); }}>
                                                <span>&times;</span>
                                            </button>
                                        </>
                                        {isLoading && (
                                            <div className='loader-bg-container'>
                                                <div className='loading-background'>
                                                    <Loader></Loader>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                ) : <div className='preview-not-selected'></div>
                                }
                            </div>
                        </div>
                    )
                        : <></>}
                </div>

                {/* <div className='prompt-container'>
                <label>Prompt</label>
                <input type="text" value={prompt} onChange={(e) => setPrompt(e.target.value)} />
            </div>

            <div className='cn-image-container'>
                <div className='cn-img'>
                    <label htmlFor="cn_img1">
                        <input type="checkbox" id="enableImage1" checked={enableImage1} onChange={() => handleCheckboxChange('cn_img1')} />
                        Enable CN_Image 1
                    </label>
                    {enableImage1 && (
                        <input type="file" id="cn_img1" onChange={(e) => handleFileChange(e, 'cn_img1')} />
                    )}
                </div>

                <div className='cn-img'>
                    <label htmlFor="cn_img2">
                        <input type="checkbox" id="enableImage2" checked={enableImage2} onChange={() => handleCheckboxChange('cn_img2')} />
                        Enable CN_Image 2
                    </label>
                    {enableImage2 && (
                        <input type="file" id="cn_img2" onChange={(e) => handleFileChange(e, 'cn_img2')} />
                    )}
                </div>

                <div className='cn-img'>
                    <label htmlFor="cn_img3">
                        <input type="checkbox" id="enableImage3" checked={enableImage3} onChange={() => handleCheckboxChange('cn_img3')} />
                        Enable CN_Image 3
                    </label>
                    {enableImage3 && (
                        <input type="file" id="cn_img3" onChange={(e) => handleFileChange(e, 'cn_img3')} />
                    )}
                </div>
            </div> */}

                <div className='btn-container'>
                    <button className='remove-bg-btn' onClick={handleUploadImage}>Generate Image</button>
                    <button className='download-btn' onClick={handleDownload}>Download Image</button>
                </div>

            </div>
        </>
    );
};

export default Home;
