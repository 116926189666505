import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './Pages/Login';
import Home from './Pages/Home';
// import Signup from './Pages/Signup';
// import LandingPage from './Pages/LandingPage';

// const BASE_URL = `http://localhost:5000`
const BASE_URL = `https://genapi.modelverse.in`


function App() {

  const isAuthenticatedUser = localStorage.getItem('userToken');
  // console.log("isAuthenticatedUser -->> ", isAuthenticatedUser)

  return (
    <Router>
      <div className='app-container'>
        <Routes>
          {/* <Route path='/' element={<LandingPage BASE_URL={BASE_URL} />}></Route> */}
          <Route path="/" element={<Login BASE_URL={BASE_URL} />}></Route>
          {/* <Route path="/signup" element={<Signup BASE_URL={BASE_URL} />}></Route> */}

          {
            isAuthenticatedUser ? (
              <>
                {/* Protected Routes */}
                <Route path="/in/*" element={<Home BASE_URL={BASE_URL} />}></Route>
                {/* <Route path="/in" element={<Navigate to="/in/remove-bg" />}></Route> */}
              </>
            ) : (
              // Redirect to login if not authenticated
              <Route path="/*" element={<Navigate to="/" />} />
            )
          }

        </Routes>
      </div>
    </Router>
  );
}
export default App;