

import { useEffect, useState } from 'react';
import './topNav.css'
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';

// import logo from '../../Assets/Images/modelverse-logo.png'

const TopNav = (props) => {
    const username = localStorage.getItem('userName');
    const userToken = localStorage.getItem('userToken');

    const link = props.currentPage === "Home" ? '' : "/in/"

    return (
        <div>
            <Navbar bg="light" expand="lg">
                {/* <Navbar.Brand href="remove-bg"><img src={logo} width='120px' alt="" /></Navbar.Brand> */}
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav nav-items" className='justify-content-end'>
                    <Nav className="mr-auto">
                        {/* <Nav.Link href="/">Home</Nav.Link> */}
                        {/* <Nav.Link href="user-gallery">Gallery</Nav.Link> */}
                        {/* <NavDropdown title="Services" id="basic-nav-dropdown">
                            <NavDropdown.Item href={`${link}remove-bg`}>Eliminate Background</NavDropdown.Item>
                            <NavDropdown.Item href={`${link}remove-text`}>Clean Text</NavDropdown.Item>
                            <NavDropdown.Item href={`${link}replace-bg`}>Transform Background</NavDropdown.Item>
                            <NavDropdown.Item href={`${link}remove-object`}>Erase Object</NavDropdown.Item>
                            <NavDropdown.Item href={`${link}image-upscale`}>Image Elevator</NavDropdown.Item>
                            <NavDropdown.Item href={`${link}reimagine`}>Reform</NavDropdown.Item>
                            <NavDropdown.Item href={`${link}text-to-image`}>Image Magic</NavDropdown.Item>
                        </NavDropdown> */}
                        {userToken ?
                            <NavDropdown title={username} id="basic-nav-dropdown">
                                {/* <NavDropdown.Header> Credits: {props.userCredits}</NavDropdown.Header> */}
                                {/* <NavDropdown.Item href={`${link}user-gallery`}>Go to Gallery</NavDropdown.Item> */}
                                {/* <NavDropdown.Item href={`${link}account-info`}>Account</NavDropdown.Item> */}
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="/" onClick={() => localStorage.removeItem('userToken')}>Logout</NavDropdown.Item>
                            </NavDropdown>
                            :
                            <Nav.Link href="login">Login/Signup</Nav.Link>
                        }
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </div>
    )
}

export default TopNav